import axios from 'axios'
import Utils from 'utils'

export const HTTP = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  timeout: 0,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
})

export const setBearerToken = (jwtToken) => {
  const { headers } = HTTP.defaults

  HTTP.defaults.headers = {
    ...headers,
    Authorization: `Bearer ${jwtToken}`,
  }
}

// Add a request interceptor
HTTP.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    Utils.cleanRequestBody(config.data)
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)
