import React from 'react'
import { Provider } from 'react-redux'
import store from '../redux/store'
import { BrowserRouter as Router } from 'react-router-dom'
import Views from '../views'
import { Switch } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import { THEME_CONFIG } from '../configs/AppConfig'
import { Route } from 'react-router-dom/cjs/react-router-dom.min'
import AppContext from './AppContext'

const MainView = () => {
  const themes = {
    dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
  }

  return (
    <AppContext>
      <Provider store={store}>
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={THEME_CONFIG.currentTheme}
          insertionPoint="styles-insertion-point"
        >
          <Router>
            <Switch>
              <Route path="/">
                <Views />
              </Route>
            </Switch>
          </Router>
        </ThemeSwitcherProvider>
      </Provider>
    </AppContext>
  )
}

export default MainView
