import React from 'react'
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_TOP,
} from 'constants/ThemeConstant'
import { APP_NAME } from 'configs/AppConfig'
import { connect } from 'react-redux'
import utils from 'utils'
import { Grid } from 'antd'
import { logo } from 'constants/Images'

const { useBreakpoint } = Grid

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props
  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  if (isMobile && !props.mobileLogo) {
    return 0
  }
  if (isNavTop) {
    return 'auto'
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`
  } else {
    return `${SIDE_NAV_WIDTH}px`
  }
}

export const Logo = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  return (
    <div
      className="logo"
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
    >
      {props.navCollapsed ? (
        <img width={50} src={logo} alt={`${APP_NAME} logo`} />
      ) : (
        <span
          style={{
            fontWeight: '400',
            fontSize: 20,
            fontFamily: 'New-Times-Roman',
          }}
        >
          Be Healthy
        </span>
      )}
    </div>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme
  return { navCollapsed, navType }
}

export default connect(mapStateToProps)(Logo)
