import { HTTP } from './HttpService'

export const ClientService = {
  create(userData) {
    return HTTP.post('/cliente', userData)
  },
  update(id, userData) {
    return HTTP.put(`/cliente/${id}`, userData)
  },
  status (auth0_uid) {
    return HTTP.get(`/cliente/status/${auth0_uid}`)
  }
}
