import React, { useContext } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import AppLayout from 'layouts/app-layout'
import AuthLayout from 'layouts/auth-layout'
import AppLocale from 'lang'
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd'
import {
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  CLIENT_PREFIX_PATH,
} from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass'
import ClientLayout from 'layouts/client-layout'
import { State } from './AppContext'

export const Views = (props) => {
  const { locale, location, direction } = props
  const currentAppLocale = AppLocale[locale]
  const { role } = useContext(State)

  useBodyClass(`dir-${direction}`)
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          {role === 'cliente' ? (
            <>
              <Route path={CLIENT_PREFIX_PATH}>
                <ClientLayout direction={direction} location={location} />
              </Route>
              <Route exact path="/">
                <Redirect to={CLIENT_PREFIX_PATH} />
              </Route>
            </>
          ) : (
            <>
              <Route exact path="/">
                <Redirect to={APP_PREFIX_PATH} />
              </Route>
              <Route path={AUTH_PREFIX_PATH}>
                <AuthLayout direction={direction} />
              </Route>
              <Route path={APP_PREFIX_PATH}>
                <AppLayout direction={direction} location={location} />
              </Route>
            </>
          )}
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme
  const { token } = auth
  return { locale, token, direction }
}

export default withRouter(connect(mapStateToProps)(Views))
