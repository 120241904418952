import React from 'react'
import { connect } from 'react-redux'
import { Button, Layout } from 'antd'
import {
  ProfileOutlined,
  FileImageOutlined,
  FileTextOutlined,
  LogoutOutlined,
  CreditCardOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'

export const HeaderNav = ({ showRoutes = true }) => {
  const { logout } = useAuth0()

  return (
    <Layout className="mb-4 mb-md-0">
      <nav
        className="navbar navbar-expand-lg navbar-dark bg-dark bg-purple"
        style={{ background: 'linear-gradient(90deg,#867ba3 0%,#9bbfc9 100%)' }}
      >
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <Link
          style={{ fontFamily: 'times-new-roman' }}
          to="/"
          className="navbar-brand"
        >
          Be Healthy
        </Link>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {showRoutes && (
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link to="/cliente/menus" className="nav-link">
                  <ProfileOutlined className="mr-1 mb-1 align-middle" />
                  Menús
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/cliente/seguimiento" className="nav-link">
                  <FileImageOutlined className="mr-1 mb-1 align-middle" />
                  Seguimiento
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/cliente/expediente" className="nav-link">
                  <FileTextOutlined className="mr-1 mb-1 align-middle" />
                  Expediente
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/cliente/metodos-pago" className="nav-link">
                  <CreditCardOutlined className="mr-1 mb-1 align-middle" />
                  Métodos de pago
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/cliente/manuales" className="nav-link">
                  <InfoCircleOutlined className="mr-1 mb-1 align-middle" />
                  Manuales
                </Link>
              </li>
            </ul>
          )}
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Button
                onClick={logout}
                icon={<LogoutOutlined className="mb-1 align-middle" />}
              >
                Cerrar sesión
              </Button>
            </li>
          </ul>
        </div>
      </nav>
    </Layout>
  )
}

const mapStateToProps = ({ theme }) => {
  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  } = theme
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  }
}

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav)
