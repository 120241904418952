import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Switch } from 'react-router-dom'
import ProtectedRoute from 'auth/protected-route'
import MainView from 'views/MainView'
import moment from 'moment'

// Styles
import './app.scss'

moment.updateLocale('en', {
  weekdaysMin: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sab'],
  monthsShort: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
})

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <ProtectedRoute path="/" component={MainView} />
        </Switch>
      </Router>
    </div>
  )
}

export default App
