import antdEnUS from 'antd/es/locale/en_US'
import enMsg from '../locales/en_US.json'

const EnLang = {
  antd: antdEnUS,
  locale: 'en-US',
  messages: {
    ...enMsg,
    Clientes: 'Clientes',
    'Nuevo cliente': 'Nuevo cliente',
    'Lista de Clientes': 'Lista de Clientes',
    Programas: 'Programas',
    'Registro de Programa': 'Registro de Programa',
    'Lista de Programas': 'Lista de Programas',
    Pagos: 'Pagos',
    'Registrar Pago': 'Registrar Pago',
    'Lista de pagos': 'Lista de pagos',
  },
}
export default EnLang
