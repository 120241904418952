import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import '../../../node_modules/bootstrap/dist/css/bootstrap.css'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/clientes/lista`}
          component={lazy(() => import(`./clientes/ClientList`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/cliente/registro`}
          component={lazy(() => import(`./clientes/ClientCreate`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/cliente/modificar/:id`}
          component={lazy(() => import(`./clientes/ClientUpdate`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/cliente/expediente/:id/:clientName`}
          component={lazy(() => import(`./clientes/ClientExpedient`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/cliente/programa/:id/:clientName`}
          component={lazy(() => import(`./clientes/NutricionalPlan`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/cliente/:id/seguimientos`}
          component={lazy(() => import(`./clientes/ClientMonitoring`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/clientes`}
          component={lazy(() => import(`./clientes`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/programas/lista`}
          component={lazy(() => import(`./programas/ProgramList`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/programa/registro`}
          component={lazy(() => import(`./programas/ProgramCreate`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/pago/registro`}
          component={lazy(() => import(`./payments/PaymentManualRegister`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/pagos/lista`}
          component={lazy(() => import('./payments/PaymentsList'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/programa/modificar/:id`}
          component={lazy(() => import(`./programas/ProgramUpdate`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/clientes/lista`}
        />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews)
